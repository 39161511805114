<template>
  <div class="has-hero-container">
    <div>

      <toolbar :dataLoaded="true">{{ t("title") }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter">

          <v-slide-group show-arrows>
            <v-slide-item>
              <div class="filter-item temporary">
                <span class="md-caption">{{ t("filter.calendar-week") }}</span>
                <date-picker
                  ref="weekDatePicker"
                  v-model="filter.dateWeek"
                  type="week"
                  :lang="lang"
                  :formatter="wochenFormat"
                  @change="dateSelected('week'); filter.dateMonth=null"
                  @clear="clearSelected()"
                  value-type="date"
                  @panel-change="calenderChanged"
                ></date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-item temporary">
                <span class="md-caption">{{ t("filter.calendar-month") }}</span>
                <date-picker
                  v-model="filter.dateMonth"
                  type="month"
                  :lang="lang"
                  format="MMMM YYYY"
                  @change="dateSelected('month'); filter.dateWeek=null"
                  @clear="clearSelected()"
                  value-type="date"
                ></date-picker>
              </div>
            </v-slide-item>

            <v-slide-item>
              <div class="filter-search-list temporary">
                <div class="filter-item">
                  <content-filter-employee
                    @item-selected="userSelected"
                  />

                </div>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-container>

      </div>

      <v-container fluid v-if="!hasData" class="statistics">
        <v-row>
          <h3>{{ t("no-data") }}</h3>
        </v-row>
      </v-container>

      <v-container fluid v-if="!userLoaded && activePeriod !== '' ">
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <v-btn
            v-if="activePeriod === 'week'"
            elevation="0"
            color="primary"
            class="icon-left"
            :loading="isDownloading"
            @click="generateWeekExcel()"
          >
            <v-icon>mdi-download</v-icon>
            {{ $t("timelog-reports.week-report-export", { getSelectedTime: selectedWeek }) }}
          </v-btn>
          <v-btn
            v-if="activePeriod === 'month'"
            elevation="0"
            color="primary"
            class="icon-left"
            :loading="isDownloading"
            @click="generateMonthExcel()"
          >
            <v-icon>mdi-download</v-icon>
            {{ $t("timelog-reports.month-report-export", { getSelectedTime: selectedMonth }) }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>

      <v-container fluid v-if="userLoaded && activePeriod !== ''">
        <v-row class="data-table-toolbar">
          <h2 class="sp-mar-l-1">{{ t("report-title") }}</h2>
          <v-spacer></v-spacer>
          <v-btn
            v-if="activePeriod === 'week'"
            elevation="0"
            color="primary"
            class="icon-left"
            :disabled="!readyToDownload"
            :loading="isDownloading"
            @click="generateWeekExcelForEmployee()"
          >
            <v-icon>mdi-download</v-icon>
            {{ t("report-export") }}
          </v-btn>
          <v-btn
            v-if="activePeriod === 'month'"
            elevation="0"
            color="primary"
            class="icon-left"
            :disabled="!readyToDownload"
            :loading="isDownloading"
            @click="generateMonthExcelForEmployee()"
          >
            <v-icon>mdi-download</v-icon>
            {{ t("report-export") }}
          </v-btn>
        </v-row>
      </v-container>

      <!-- only show if user and one of times is selected -->
      <v-container fluid v-if="timelogEntries">
        <v-row>
          <v-data-table
            :headers="headers"
            :items="timelogEntries"
            :loading="isLoading"
            :options.sync="options"
            :server-items-length="timelogEntriesCount"
            class="elevation-0 small-font-size"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': $t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ item.work_date | momentWeekday }} {{ item.work_date | momentDate }}
            </template>

            <template v-slot:[`item.number`]="{ item }">
              {{ item.user.number }}
            </template>

            <template v-slot:[`item.lastname`]="{ item }">
              {{ item.user.lastname }}
            </template>

            <template v-slot:[`item.firstname`]="{ item }">
              {{ item.user.firstname }}
            </template>

            <template v-slot:[`item.object`]="{ item }">
              {{ item.customer.name }}
            </template>

            <template v-slot:[`item.work_start_time`]="{ item }">
              {{ formatTimeShort(item.work_start_time) }}
            </template>

            <template v-slot:[`item.work_end_time`]="{ item }">
              {{ formatTimeShort(item.work_end_time) }}
            </template>

            <template v-slot:[`item.work_duration_seconds`]="{ item }">
              {{ item.work_duration_seconds | momentSecondsToHours }}
            </template>

          </v-data-table>
        </v-row>
      </v-container>
    </div>
  </div>

</template>

<script>
import { HTTP } from "@/auth"
import moment from "moment"
import { EventBus } from "@/event-bus.js"
import toolbar from "@/components/layouts/Navigation"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import SimpleFilter from "@/components/filter/SimpleFilter"
import show from "@/views/ReportsShow"
import loading from "@/components/layouts/Loading"
import TimesheetStatusFilter from "@/components/filter-components/TimesheetStatusFilter"
import AppFilterSearchField from "@/components/vuetify/AppFilterSearchField"
import { mapDataTableConfig } from "@/infrastructure/dataTableHelper"
import ContentFilterEmployee from "@/components/filter-components/ContentFilterEmployee.vue"

export default {
  name: "TimesheetIndex",
  components: {
    ContentFilterEmployee,
    SimpleFilter,
    DatePicker,
    toolbar,
    show,
    loading,
    TimesheetStatusFilter,
    AppFilterSearchField
  },
  watch: {
    filter: {
      handler() {
        this.loadData()
      },
      deep: true
    },
    options: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.loadData()
        }
      },
      deep: true
    }
  },
  filters: {
    momentWeekday: function(date) {
      return moment(date).locale("de").format("ddd")
    },
    momentDate: function(date) {
      return moment(date).format("DD.MM.YYYY")
    },
    momentSecondsToHours: function(seconds) {
      return (Math.round((seconds / 3600) * 100) / 100).toFixed(2)
    }
  },
  data() {
    return {
      hasData: true,
      isLoading: false,
      timelogEntries: null,
      timelogEntriesCount: null,

      filter: {
        dateWeek: null,
        dateMonth: null,
        selectedUser: null
      },
      options: {},
      lang: {
        formatLocale: {
          weekdaysMin: ["SO", "MO", "DI", "MI", "DO", "FR", "SA"],
          monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
          months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
          firstDayOfWeek: 1,
          firstWeekContainsDate: 1
        }
      },
      wochenFormat: {
        stringify: (date) => {
          return date ? "Woche " + moment(date).isoWeek() : ""
        },
        parse: (value) => {
          return value ? moment.isoWeek(value).toDate() : null
        }
      },
      isDownloading: false,
      userLoaded: false,
      activePeriod: ""

    }
  },
  mounted() {
    this.setFirstDateToFirstWeekOfYear(moment()) // for correct numbers of calender week
  },
  created() {
  },

  computed: {
    headers() {
      let columns = []
      columns.push(
        {
          text: this.t("table.date"),
          align: "start",
          sortable: true,
          value: "date"
        },
        {
          text: this.t("table.number"),
          value: "number",
          sortable: false,
          cellClass: "text-middle"
        },
        {
          text: this.t("table.lastname"),
          value: "lastname",
          sortable: true,
          width: 200,
          cellClass: "text-middle"
        },
        {
          text: this.t("table.firstname"),
          value: "firstname",
          sortable: true,
          width: 200,
          cellClass: "text-middle"
        },
        {
          text: this.t("table.object"),
          value: "object",
          sortable: false,
          cellClass: "text-middle"
        },
        {
          text: this.t("table.start"),
          align: "end",
          value: "work_start_time",
          sortable: false
        },
        {
          text: this.t("table.end"),
          align: "end",
          value: "work_end_time",
          sortable: false
        },
        {
          text: this.t("table.time"),
          align: "end",
          value: "work_duration_seconds",
          sortable: false
        }
      )
      return columns
    },
    readyToDownload: function() {
      return !this.isDownloading
    },
    selectedWeek: function() {
      return moment(this.filter.dateWeek).isoWeek()
    },
    selectedMonth: function() {
      return this.getMonthName(moment(this.filter.dateMonth).month())
    }
  },
  methods: {
    t: function(key) {
      return this.$t("timelog-reports." + key)
    },
    setFirstDateToFirstWeekOfYear(date) { // for correct numbers of calender week
      let startOfYear = moment(date).startOf("year")
      let firstDayofFirstWeek = 1
      if (startOfYear.isoWeek() != 1) {
        firstDayofFirstWeek = startOfYear.add(1, "w").startOf("isoWeek").dayOfYear()
      }
      this.lang.formatLocale.firstWeekContainsDate = firstDayofFirstWeek
    },
    calenderChanged(panel, oldPanel) {
      this.setFirstDateToFirstWeekOfYear(this.$refs.weekDatePicker.$refs.popup.$children[0].innerCalendar)
    },
    dateSelected(type) {
      this.activePeriod = type
      this.loadData()
    },
    buildFilter() {
      let filter = {}
      switch (this.activePeriod) {
        case "week":
          filter.date = moment(this.filter.dateWeek).startOf("isoWeek").format("YYYY-MM-DD")
          filter.after = moment(this.filter.dateWeek).startOf("isoWeek").format("YYYY-MM-DD")
          filter.before = moment(this.filter.dateWeek).startOf("isoWeek").add(7, "days").format("YYYY-MM-DD")
          break
        case "month":
          filter.date = moment(this.filter.dateMonth).startOf("month").format("YYYY-MM-DD")
          filter.after = moment(this.filter.dateMonth).startOf("month").format("YYYY-MM-DD")
          filter.before = moment(this.filter.dateMonth).startOf("month").add(1, "month").format("YYYY-MM-DD")
          break
      }

      filter.q = this.filter.selectedUser

      filter = mapDataTableConfig(filter, this.options)

      return filter
    },
    async loadData() {
      if (!this.activePeriod) {
        return
      }
      this.timelogEntries = null
      this.isLoading = true
      try {
        let filter = this.buildFilter()
        let response = await HTTP.get("timelog", { params: filter })
        this.timelogEntries = response.data.data
        this.timelogEntriesCount = response.data.total
      } finally {
        this.hasData = this.timelogEntries && this.timelogEntries.length > 0
        this.isLoading = false
      }
    },
    async generateWeekExcel() {
      this.isDownloading = true
      try {
        let filter = this.buildFilter()
        let response = await HTTP.post("timelog/reporting/week-excel", filter, { responseType: "arraybuffer" })
        let filename = "Wochenzeitrapporte-Woche_" + this.selectedWeek + "-%TIMESTAMP%.zip"
        this.startDataDownload(response.data, response.headers["content-type"], filename)
      } finally {
        this.isDownloading = false
      }
    },
    async generateMonthExcel() {
      this.isDownloading = true
      try {
        let filter = this.buildFilter()
        let response = await HTTP.post("timelog/reporting/month-excel", filter, { responseType: "arraybuffer" })
        let filename = "Monatszeitrapporte-Monat_" + this.selectedMonth + "-%TIMESTAMP%.zip"
        this.startDataDownload(response.data, response.headers["content-type"], filename)
      } finally {
        this.isDownloading = false
      }
    },
    async generateWeekExcelForEmployee() {
      this.isDownloading = true
      try {
        let filter = this.buildFilter()
        let response = await HTTP.post("timelog/reporting/week-excel-employee", filter, { responseType: "arraybuffer" })
        let filename = "Wochenzeitrapport-Woche_" + this.selectedWeek + "-%TIMESTAMP%.xlsx"
        this.startDataDownload(response.data, response.headers["content-type"], filename)
      } finally {
        this.isDownloading = false
      }
    },
    async generateMonthExcelForEmployee() {
      this.isDownloading = true
      try {
        let filter = this.buildFilter()
        let response = await HTTP.post("timelog/reporting/month-excel-employee", filter, { responseType: "arraybuffer" })
        let filename = "Monatszeitrapport-Monat_" + this.selectedMonth + "-%TIMESTAMP%.xlsx"
        this.startDataDownload(response.data, response.headers["content-type"], filename)
      } finally {
        this.isDownloading = false
      }
    },
    clearSelected() {
      this.activePeriod = ""
    },
    getMonthName(i) {
      return this.lang.formatLocale.months[i]
    },
    userSelected(id, data) {
      this.filter.selectedUser = id
      this.userLoaded = this.filter.selectedUser
    }
  }
}
</script>

<style lang="scss">
/* temp */
.filter-search-list.temporary {

  .md-caption {
    font-weight: 500;
    letter-spacing: 0.01em;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF !important;
  }

  .sp-search-field-lists {
    margin: 0;
    padding-top: 24px;

    &:after {
      content: none;
    }

    .sp-search-field-inner {
      border-radius: 4px;
    }
  }
}

.filter-item.temporary {

  .md-caption {
    display: block;
    font-weight: 500;
    letter-spacing: 0.01em;
    font-size: 14px;
    line-height: 24px;
  }

  .mx-datepicker {
    margin: 0;
    padding: 0;

    .mx-input-wrapper {
      border-radius: 4px;
    }
  }
}

</style>

<style scoped lang="scss">
/* temp */
.sp-hero .filter-search-list {
  padding-top: 26px;
  // width: calc(66.66% - 134px);
}

/* temp */
.sp-app.v-application {

  .container--fluid.hero-filter {
    padding-bottom: 28px;
  }
}

/* temp */
.data-table-toolbar h2 {
  margin: 0;
}

/* temp */
.md-table-card {
  margin-bottom: 60px;
}

.user-selected .md-icon {
  margin: 4px !important;
  color: #FFF !important;
}

.user-selected .md-icon.delete {
  margin-left: auto !important;
}

.user-selected .md-icon.delete:hover {
  cursor: pointer;
}
</style>

