<template>
  <md-list-item>
    <v-icon>{{ item.icon }}</v-icon>
    <span class="module-title">{{ item.title }}</span>
    <md-list-expand>
      <md-list>
        <md-list-item class="md-inset" v-if="moduleExists('timelog_stamp')">
          <router-link :to="{ path: '/timestamps' }">
            <span>{{ $t("navigation.timelog-stamp") }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset" v-if="moduleExists('timelog_basic')">
          <router-link :to="{ path: '/time' }">
            <span>{{ $t("navigation.timelog-timelogs") }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset" v-if="moduleExists('timelog_locktimemode')">
          <router-link :to="{ path: '/time-lock' }">
            <span>{{ $t("navigation.timelog-timelock") }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset" v-if="moduleExists('timelog_report') && user && user.role === 'admin'">
          <router-link :to="{ path: '/time-sheets' }">
            <span>{{ $t("navigation.timelog-reports") }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset" v-if="moduleExists('timelog_reportoverview') && user && user.role === 'admin' && theme === 'hgi'">
          <router-link :to="{ path: '/time-sheets-all' }">
            <span>{{ $t("navigation.timelog-reportoverview") }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset" v-if="moduleExists('time_sheets_month') && user && user.role === 'admin' && theme === 'hgi'">
          <router-link :to="{ path: '/time-sheets-month' }">
            <span>{{ $t("navigation.timelog-salaryreport") }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset" v-if="moduleExists('timelog_proof_report')">
          <router-link :to="{ path: '/timelog-proof-report' }">
            <span>{{ $t("navigation.timelog-proof-report") }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset" v-if="moduleExists('timelog_expense_report')">
          <router-link :to="{ path: '/timelog-expense-report' }">
            <span>{{ $t("navigation.timelog-expense-report") }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset" v-if="moduleExists('timelog_approval')">
          <router-link :to="{ path: '/timesheet-approval' }">
            <span>{{ $t("navigation.timesheet") }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset" v-if="user.role === 'admin' && moduleExists('timelog_basic')">
          <router-link :to="{ path: '/settings/timelog' }">
            <span>{{ $t("navigation.settings") }}</span>
          </router-link>
        </md-list-item>
      </md-list>
    </md-list-expand>
  </md-list-item>
</template>

<script>
import store from "store"

export default {
  name: "TimesheetListItem",
  data() {
    return {}
  },
  computed: {
    theme() {
      return store.get("theme")
    },
  },
  methods: {
    moduleExists(moduleName) {
      let active = false
      this.activeModules.forEach(function(module) {
        if (moduleName == module.module_name) {
          active = true
        }
      })
      return active
    }
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: {}
    },
    user: {
      type: Object,
      required: false,
      default: {}
    },
    activeModules: {
      type: Array,
      required: false
    }
  }

}
</script>
