<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">
          <app-filter-apply-refresh-button
            :has-changes="filterStore.hasChanges"
            :is-loading="loading"
            @click="applyFilter(); options.page = 1"
          />

          <v-slide-group show-arrows>

            <v-slide-item>
              <div>
                <div class="filter-item">
                  <app-filter-date-picker
                    :filterLabel="$t('components.app-filter-date-picker.label.startdate')"
                    v-model="filterStore.filter.dateStart"
                    id="dateStart"
                  ></app-filter-date-picker>
                </div>
              </div>
            </v-slide-item>
            <v-slide-item>
              <div>
                <div class="filter-item">
                  <app-filter-date-picker
                    :filterLabel="$t('components.app-filter-date-picker.label.enddate')"
                    v-model="filterStore.filter.dateEnd"
                    id="dateEnd"
                    :clearable="true"
                  ></app-filter-date-picker>
                </div>
              </div>
            </v-slide-item>
            <v-slide-item>

              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <content-filter-customer
                      :get-search-url="getCustomerSearchUrl"
                      v-model="filterStore.filter.selectedCustomer"
                      :preselected="filterStore.appliedFilter.selectedCustomer"
                    ></content-filter-customer>
                  </div>
                </div>
              </div>

            </v-slide-item>
            <v-slide-item>

              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <user-filter-widget
                      v-model="filterStore.filter.selectedUser"
                      :preselected="filterStore.appliedFilter.selectedUser"
                      :include-inactive="true"
                    ></user-filter-widget>
                  </div>
                </div>
              </div>

            </v-slide-item>

          </v-slide-group>

        </v-container>

      </div>

      <v-container fluid>

        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            outlined
            class="icon-left"
            :disabled="!readyToDownloadEmployeeDay"
            :loading="isDownloadingEmployeeDay"
            @click="saveEmployeeDay()"
          >
            <v-icon>mdi-download</v-icon>
            {{ t('excel-export-employee-day') }}
          </v-btn>

          <v-btn
            elevation="0"
            color="primary"
            outlined
            class="icon-left ml-2"
            :disabled="!readyToDownload"
            :loading="isDownloadingCSV"
            @click="saveAsCSV()"
          >
            <v-icon>mdi-download</v-icon>
            {{ t('excel-export') }}
          </v-btn>

          <v-btn
            elevation="0"
            color="primary"
            outlined
            class="icon-left ml-2"
            :disabled="!readyToDownloadExcel"
            :loading="isDownloadingExcel"
            @click="downloadHorizontalExcel()"
          >
            <v-icon>mdi-download</v-icon>
            {{ t('excel-export-day-view') }}
          </v-btn>

          <v-btn
            elevation="0"
            color="primary"
            outlined
            class="icon-left ml-2"
            :disabled="!readyToDownloadAnalysis"
            :loading="isDownloadingAnalysis"
            @click="downloadAnalysis()"
          >
            <v-icon>mdi-download</v-icon>
            {{ t('excel-analysis') }}
          </v-btn>

          <v-btn
            elevation="0"
            color="primary"
            outlined
            class="icon-left ml-2"
            :to="{ path: '/time/create'}"
          >
            <v-icon>mdi-plus</v-icon>
            {{ t('create-new-time-entry') }}
          </v-btn>
        </v-row>

        <v-row v-if="dataLoaded && timelogItems.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>

          <v-data-table
            :headers="headers"
            :items="timelogItems"
            :options.sync="options"
            :server-items-length="totalTimelogs"
            :loading="loading"
            class="elevation-0 small-font-size"
            :sort-by.sync="filterStore.table.sortBy"
            :sort-desc.sync="filterStore.table.sortDesc"
            :page.sync="filterStore.table.currentPage"
            :items-per-page.sync="filterStore.table.itemsPerPage"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [10, 25, 50, 100],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ item.work_date | momentWeekday }}<br>{{ item.work_date | momentDate }}
            </template>

            <template v-slot:[`item.number`]="{ item }">
              {{ item.user.number }}
            </template>

            <template v-slot:[`item.staff_number`]="{ item }">
              {{ item.user.staff_number }}
            </template>

            <template v-slot:[`item.lastname`]="{ item }">
              {{ item.user.lastname }}
            </template>

            <template v-slot:[`item.firstname`]="{ item }">
              {{ item.user.firstname }}
            </template>

            <template v-slot:[`item.object`]="{ item }">
              {{ item.customer.name }}
            </template>

            <template v-slot:[`item.work_start_time`]="{ item }">
              {{ formatTime(item.work_start_time) }}
            </template>

            <template v-slot:[`item.work_end_time`]="{ item }">
              {{ formatTime(item.work_end_time) }}
            </template>

            <template v-slot:[`item.target_seconds`]="{ item }">
              <span v-if="item.target_seconds > 0">{{ item.target_seconds | momentSecondsToHours }}</span>
            </template>

            <template v-slot:[`item.work_duration_seconds`]="{ item }">
              {{ item.work_duration_seconds | momentSecondsToHours }}
            </template>

            <template v-slot:[`item.total`]="{ item }">
              {{ item.totalDuration | momentSecondsToHours }}
            </template>

            <template v-slot:[`item.target_match`]="{ item }">
              <v-icon v-if="!item.target_match" class="mt-3" small color="error">info</v-icon>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <router-link :to="{ path: '/time/edit', query: { userId: item.user.id, date: item.work_date } }"
                           class="edit-row-table">
                <v-icon v-show="canEdit(item)" small class="edit-row-button">edit</v-icon>
              </router-link>
            </template>
          </v-data-table>

        </v-row>

      </v-container>

    </div>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import store from 'store'
import moment from 'moment'
import loading from '@/components/layouts/Loading'
import toolbar from '@/components/layouts/Navigation'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import UserFilterWidget from '@/components/filter-components/UserFilterWidget'
import ContentFilterCustomer from '@/components/filter-components/ContentFilterCustomer'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import { useTimelogFilterStore } from '@/stores/TimelogFilterStore'
import { hasPermission } from '@/services/PermissionService'
import AppFilterApplyRefreshButton from "@/components/vuetify/AppFilterApplyRefreshButton.vue"
import { mapDataTableConfig } from "@/infrastructure/dataTableHelper"

export default {
  name: 'TimeIndex',
  components: {
    AppFilterApplyRefreshButton,
    AppFilterApplyButton,
    ContentFilterCustomer,
    UserFilterWidget,
    toolbar,
    loading,
    AppFilterDatePicker,
    AppFilterSearchField
  },
  setup() {
    const filterStore = useTimelogFilterStore()
    return { filterStore }
  },
  created() {
    this.getUser()
  },
  data() {
    return {
      loading: false,
      dataLoaded: false,
      totalTimelogs: 0,
      timelogItems: [],
      options: {},
      isDownloadingCSV: false,
      isDownloadingExcel: false,
      isDownloadingEmployeeDay: false,
      isDownloadingAnalysis: false,
      config: {
        record_mode: '',
        external_hours: false,
      },
      user: null,
    }
  },
  async mounted() {
    await this.loadConfiguration()
  },
  computed: {
    readyToDownload: function () {
      return !this.isDownloadingCSV
    },
    readyToDownloadExcel: function () {
      return !this.isDownloadingExcel
    },
    readyToDownloadEmployeeDay: function () {
      return !this.isDownloadingEmployeeDay
    },
    readyToDownloadAnalysis: function () {
      return !this.isDownloadingAnalysis
    },
    headers() {
      let columns = []
      columns.push(
          {
            text: this.t('table.date'),
            align: 'start',
            sortable: true,
            value: 'date'
          },
          {
            text: this.t('table.number'),
            value: 'number',
            sortable: false,
            cellClass: 'text-middle'
          },
          {
            text: this.t('table.staff_number'),
            value: 'staff_number',
            sortable: false,
            cellClass: 'text-middle'
          },
          {
            text: this.t('table.lastname'),
            value: 'lastname',
            sortable: true,
            width: 200,
            cellClass: 'text-middle'
          },
          {
            text: this.t('table.firstname'),
            value: 'firstname',
            sortable: true,
            width: 200,
            cellClass: 'text-middle'
          },
          {
            text: this.t('table.object'),
            value: 'object',
            sortable: false,
            cellClass: 'text-middle'
          },
          {
            text: this.t('table.customer_project'),
            value: 'customer_project',
            sortable: false,
            cellClass: 'text-middle'
          },
      )

      if (this.config.record_mode !== 'hours') {
        columns.push(
            {
              text: this.t('table.start'),
              align: 'end',
              value: 'work_start_time',
              sortable: false
            },
            {
              text: this.t('table.end'),
              align: 'end',
              value: 'work_end_time',
              sortable: false
            },
        )
      }

      if (this.config.external_hours) {
        columns.push(
            {
              text: this.t('table.target-hours'),
              align: 'end',
              value: 'target_seconds',
              sortable: false
            },
        )
      }

      columns.push(
          {
            text: this.t('table.time'),
            align: 'end',
            value: 'work_duration_seconds',
            sortable: false
          },
      )

      if (this.config.external_hours) {
        columns.push(
            {
              text: this.t('table.discrepancy'),
              value: 'target_match',
              sortable: false
            },
        )
      }

      columns.push(
          {
            text: this.t('table.edit'),
            align: 'center',
            value: 'edit',
            sortable: false,
            cellClass: 'text-middle'
          },
      )

      return columns
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm')
    },
    momentDateTime: function (date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    },
    momentWeekday: function (date) {
      return moment(date).locale('de').format('dddd')
    },
    momentSecondsToHours: function (seconds) {
      return (Math.round((seconds / 3600) * 100) / 100).toFixed(2)
    }
  },
  methods: {
    t: function (key) {
      return this.$t('timelog-timelogs.list.' + key);
    },
    canEdit: function (item) {
      if (['his', 'tra', 'tri'].includes(store.get('theme'))) {
        return hasPermission(['timelog_admin']) || this.isAdmin()
      } else {
        return (hasPermission(['timelog_admin']) || this.isAdmin()) && item.can_edit
      }
    },
    getUser() {
      this.user = store.get('user')
    },
    isAdmin() {
      return this.user && this.user.role === "admin"
    },
    async loadConfiguration() {
      let response = await HTTP.get('timelog/settings')
      this.config.record_mode = response.data.record_mode
      this.config.external_hours = response.data.external_hours
    },
    async applyFilter() {
      this.filterStore.filterApplied()
      await this.reload()
    },
    async reload() {
      await this.getDataFromApi()
    },
    async getDataFromApi() {
      this.loading = true
      this.dataLoaded = false
      try {
        await this.apiCall()
        this.dataLoaded = true
      } finally {
        this.loading = false
      }
    },
    buildFilter() {
      let filter = {};
      if (this.filterStore.filter.dateStart) {
        filter.after = this.filterStore.filter.dateStart
      }
      if (this.filterStore.filter.dateEnd) {
        let dateEnd = new Date(this.filterStore.filter.dateEnd)
        dateEnd.setDate(dateEnd.getDate() + 1)
        filter.before = this.formatDateIso(dateEnd)
      }
      if (this.filterStore.filter.selectedUser) {
        filter.q = this.filterStore.filter.selectedUser?.id
      }
      if (this.filterStore.filter.selectedCustomer) {
        filter.c = this.filterStore.filter.selectedCustomer?.id
      }
      filter = mapDataTableConfig(filter, this.options)
      return filter;
    },
    async apiCall() {
      let filter = this.buildFilter()
      let response = await HTTP.get('timelog', {params: filter})
      this.timelogItems = response.data.data
      this.totalTimelogs = response.data.total
    },
    async saveAsCSV() {
      let filter = this.buildFilter()
      try {
        this.isDownloadingCSV = true
        let response = await HTTP.post('dataexport/excel/timelogs', filter, {responseType: 'arraybuffer'})
        let filename = 'TimeApp-%TIMESTAMP%_Zeiterfassung.xlsx'
        this.startDataDownload(response.data, response.headers['content-type'], filename)
      } finally {
        this.isDownloadingCSV = false
      }
    },
    async downloadHorizontalExcel() {
      let filter = this.buildFilter()
      try {
        this.isDownloadingExcel = true
        let response = await HTTP.post('dataexport/excel/timelogs-horizontal', filter, {responseType: 'arraybuffer'})
        let filename = 'Zeiterfassung-%TIMESTAMP%.xlsx'
        this.startDataDownload(response.data, response.headers['content-type'], filename)
      } finally {
        this.isDownloadingExcel = false
      }
    },
    async downloadAnalysis() {
      let filter = this.buildFilter()
      try {
        this.isDownloadingAnalysis = true
        let response = await HTTP.post('dataexport/excel/timelogs-analysis', filter, {responseType: 'arraybuffer'})
        let filename = 'Zeiteintraege-%TIMESTAMP%.xlsx'
        this.startDataDownload(response.data, response.headers['content-type'], filename)
      } finally {
        this.isDownloadingAnalysis = false
      }
    },
    async saveEmployeeDay() {
      let filter = this.buildFilter()
      try {
        this.isDownloadingEmployeeDay = true
        let response = await HTTP.post('dataexport/excel/customer-overview', filter, {responseType: 'arraybuffer'})
        let filename = 'KundenTagesAnsicht-%TIMESTAMP%.xlsx'
        this.startDataDownload(response.data, response.headers['content-type'], filename)
      } finally {
        this.isDownloadingEmployeeDay = false
      }
    },
    getCustomerSearchUrl(val) {
      return 'customers?q=' + val
    }
  }
}
</script>
